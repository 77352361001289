.ac-page-signin {

  .ac-signin-google-logo {
    border: none;
    display: inline-block;
    height: 18px;
    vertical-align: middle;
    width: 18px;
    margin-right: 0.4rem;
  }

  .ac-sign-in-wrap {
    width: 250px;
    max-width: 80vw;
    position: absolute;
    top: 40%;
    left:50%;
    transform: translate(-50%, -50%);
    text-align: center;
  
    .ac-sign-in-logo {
      max-width: 120px;
      height: auto;
      margin-bottom: 3.2rem;
      -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      opacity: 0.5;
    }
  }
}
