@import  "./vars";


html {
  scroll-behavior: smooth;
  // to stop extra body size for some reason
  padding-bottom: 2rem;
  // background-color: white;
}

html::-webkit-scrollbar,
aside::-webkit-scrollbar,
*::-webkit-scrollbar
{
  width: 8px;
  height: 8px;
  background-color: transparent;
}

html::-webkit-scrollbar-thumb,
aside::-webkit-scrollbar-thumb,
*::-webkit-scrollbar-thumb
{
  border-radius: 4px;
  background-color: rgba(55, 71, 79, .3);
}
html::-webkit-scrollbar-thumb:hover,
aside::-webkit-scrollbar-thumb:hover,
*::-webkit-scrollbar-thumb:hover
{
  background-color: rgba(55, 71, 79, .7);
}

html.scroll-thumb-on-hover::-webkit-scrollbar-thumb,
aside.scroll-thumb-on-hover::-webkit-scrollbar-thumb,
*.scroll-thumb-on-hover::-webkit-scrollbar-thumb
{
  background-color: transparent;
}


select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 1.2rem;
}

.AC-App--Has-Widget {
  // padding-bottom: 6.4rem;
  .Polaris-Frame__Content {
    // padding-bottom: 6.4rem;
  }
}

.Polaris-Sheet__Container {
  z-index: 999999;
}

.Text-Center {
  text-align: center;
}
.AC-Container {
  width: 100%;
  padding: $grid-padding;
  margin-right: auto;
  margin-left: auto;
  
  &.Full-Height {
    height: 100%;
  }
}


.AC-Row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  margin-left: -$grid-padding;
  margin-right: -$grid-padding;

  &.MA-0 {
    margin: 0;
  }

  &.Wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  &.Align-Center {
    align-content: center;
    align-items: center;
  }
  &.Justify-Center {
    justify-content: center;
    justify-items: center;
  }
  &.Full-Height {
    height: 100%;
  }
}
